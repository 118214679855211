export const saveToLocalStorage = ({
  items,
  inputs,
  i,
  isCodeToggled,
  fileName,
  ifCombinations,
  isFirstTime,
}) => {
  console.log(`saved to local storage ${isFirstTime}`);
  localStorage.setItem('items', JSON.stringify(items));
  localStorage.setItem('inputs', JSON.stringify(inputs));
  localStorage.setItem('i', i.toString());
  localStorage.setItem('isCodeToggled', isCodeToggled.toString());
  localStorage.setItem('fileName', fileName);
  localStorage.setItem('ifCombinations', JSON.stringify([...ifCombinations]));
  localStorage.setItem('isFirstTime', isFirstTime === 'true');
};

export const loadFromLocalStorage = () => {
  const items = JSON.parse(localStorage.getItem('items'));
  const inputs = JSON.parse(localStorage.getItem('inputs'));
  const i = parseInt(localStorage.getItem('i'), 10);
  const isCodeToggled = localStorage.getItem('isCodeToggled') === 'true';
  const fileName = localStorage.getItem('fileName');
  const ifCombinations = new Map(
    JSON.parse(localStorage.getItem('ifCombinations')),
  );
  const isFirstTime = localStorage.getItem('isFirstTime') === 'true';

  console.log(`loaded from local storage ${items}`);

  return {
    items,
    inputs,
    i,
    isCodeToggled,
    fileName,
    ifCombinations,
    isFirstTime,
  };
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
