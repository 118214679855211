import {
  INCREMENT,
  FUNCTION_DECLARATION,
  ASSIGNMENT,
  RETURN_STATEMENT,
  FOR_LOOP,
  IF_STATEMENT,
  ELIF_STATEMENT,
  ELSE_STATEMENT,
  PRINT_STATEMENT,
  PRINT_SAME_LINE,
  WHILE_LOOP,
  MAP_STATEMENT,
  FILTER_STATEMENT,
  LIST_INITIALIZATION,
  COMMENT,
} from './block-types';

export const loadFromExample = (index) => {
  const items = [
    [
      // 0, fibonacci
      {
        id: 10000,
        children: [
          {
            id: 10004,
            children: [{ id: 10002 }],
          },
          {
            id: 10006,
          },
        ],
      },
      {
        id: 0,
      },
    ],
    [
      // 1, factorial
      {
        id: 10000,
        children: [
          {
            id: 10004,
            children: [{ id: 10002 }],
          },
          {
            id: 10006,
          },
        ],
      },
      {
        id: 0,
      },
    ],
    [
      // 2, fizz buzz
      {
        id: 10000,
      },
      {
        id: 10002,
      },
      {
        id: 10004,
      },
      {
        id: 10006,
      },
      {
        id: 0,
      },
      {
        id: 10008,
        children: [
          {
            id: 10010,
            children: [{ id: 10028 }],
          },
          {
            id: 10012,
            children: [{ id: 10026 }],
          },
          {
            id: 10018,
            children: [{ id: 10024 }],
          },
          {
            id: 10014,
            children: [{ id: 10022 }],
          },
        ],
      },
    ],
    [
      // 3, map and filter
      { id: 0 },
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
    ],
    [
      // 4, gcd
      {
        id: 0,
        children: [
          {
            id: 1,
            children: [{ id: 2 }, { id: 3 }, { id: 4 }],
          },
          {
            id: 5,
          },
        ],
      },
      {
        id: 6,
      },
      {
        id: 7,
      },
    ],
    [
      // 5, minimum value in a list
      {
        id: 0,
        children: [
          {
            id: 1,
          },
          {
            id: 2,
            children: [
              {
                id: 3,
                children: [{ id: 4 }],
              },
            ],
          },
          { id: 5 },
        ],
      },
      {
        id: 6,
      },
      {
        id: 7,
      },
    ],
    [
      // 6, sum of elements in a list
      {
        id: 0,
        children: [
          { id: 1 },
          {
            id: 2,
            children: [{ id: 3 }],
          },
          { id: 7 },
        ],
      },
      {
        id: 5,
      },
      {
        id: 6,
      },
    ],
    [
      // 7, two sum
      { id: 8 },
      { id: 9 },
      {
        id: 0,
        children: [
          {
            id: 2,
            children: [
              {
                id: 3,
                children: [
                  {
                    id: 4,
                    children: [{ id: 5 }],
                  },
                ],
              },
            ],
          },
          {
            id: 10,
          },
        ],
      },
      { id: 6 },
      { id: 7 },
    ],
    [
      // 8
      { id: 8 },
      { id: 9 },
      { id: 10 },
      { id: 11 },
      { id: 12 },
      { id: 13 },
      { id: 1 },
      { id: 2 },
      {
        id: 3,
        children: [
          {
            id: 4,
            children: [{ id: 5 }, { id: 6 }],
          },
          { id: 7 },
        ],
      },
      { id: 14 },
      { id: 15 },
    ],
  ][index];
  const inputs = [
    {
      // 0, fibonacci
      10000: {
        type: FUNCTION_DECLARATION,
        input1: 'fibonacci',
        input2: 'n',
      },
      10004: {
        type: IF_STATEMENT,
        input1: 'n == 0 or n == 1',
      },
      10002: {
        type: 4,
        input1: 'n',
      },
      10006: {
        type: RETURN_STATEMENT,
        input1: 'fibonacci(n-1) + fibonacci(n-2)',
      },
      0: {
        type: PRINT_STATEMENT,
        input1: 'fibonacci(10)',
      },
    },
    {
      // 1, factorial
      10000: {
        type: FUNCTION_DECLARATION,
        input1: 'factorial',
        input2: 'n',
      },
      10004: {
        type: IF_STATEMENT,
        input1: 'n == 0 or n == 1',
      },
      10002: {
        type: 4,
        input1: '1',
      },
      10006: {
        type: RETURN_STATEMENT,
        input1: 'n',
      },
      0: {
        type: PRINT_STATEMENT,
        input1: 'factorial(8)',
      },
    },
    {
      // 2, fizz buzz
      10000: {
        type: 24,
        input1: 'Fizz Buzz - print integers from 1 to N',
      },
      10002: {
        type: 24,
        input1: 'but, print "Fizz" if an integer is divisible by 3,',
      },
      10004: {
        type: 24,
        input1: 'print “Buzz” if an integer is divisible by 5,',
      },
      10006: {
        type: 24,
        input1: 'print "FizzBuzz" if an integer is divisible by both 3 and 5',
      },
      10008: {
        type: 6,
        input1: 'i',
        input2: '1, N+1',
      },
      10010: {
        type: IF_STATEMENT,
        input1: 'i % 3 == 0 and i % 5 == 0',
      },
      10028: {
        type: PRINT_STATEMENT,
        input1: '"FizzBuzz"',
      },
      10012: {
        type: ELIF_STATEMENT,
        input1: 'i % 3 == 0',
      },
      10026: {
        type: PRINT_STATEMENT,
        input1: '"Fizz"',
      },
      10018: {
        type: ELIF_STATEMENT,
        input1: 'i % 5 == 0',
      },
      10024: {
        type: PRINT_STATEMENT,
        input1: '"Buzz"',
      },
      10014: {
        type: ELSE_STATEMENT,
      },
      10022: {
        type: PRINT_STATEMENT,
        input1: 'i',
      },
      0: {
        type: ASSIGNMENT,
        input1: 'N',
        input2: '20',
      },
    },
    {
      // 3, map and filter
      0: {
        type: COMMENT,
        input1: 'Given a list, get the square of all odd numbers in the list',
      },
      1: {
        type: LIST_INITIALIZATION,
        input1: 'lst',
        input2: '1, 4, 3, 7, 8, 9',
      },
      2: {
        type: FILTER_STATEMENT,
        input1: 'x',
        input2: 'x % 2 == 1',
        input3: 'lst',
        input4: 'odd_numbers',
      },
      3: {
        type: MAP_STATEMENT,
        input1: 'x',
        input2: 'x ** 2',
        input3: 'odd_numbers',
        input4: 'squared',
      },
      4: {
        type: PRINT_STATEMENT,
        input1: 'odd_numbers',
      },
      5: {
        type: PRINT_STATEMENT,
        input1: 'squared',
      },
    },
    {
      // 4, gcd
      0: {
        type: FUNCTION_DECLARATION,
        input1: 'get_gcd',
        input2: 'a, b',
      },
      1: {
        type: WHILE_LOOP,
        input1: 'b > 0',
      },
      2: {
        type: ASSIGNMENT,
        input1: 'modulo',
        input2: 'a % b',
      },
      3: {
        type: ASSIGNMENT,
        input1: 'a',
        input2: 'b',
      },
      4: {
        type: ASSIGNMENT,
        input1: 'b',
        input2: 'modulo',
      },
      5: {
        type: RETURN_STATEMENT,
        input1: 'a',
      },
      6: {
        type: PRINT_STATEMENT,
        input1: 'get_gcd(10, 3)',
      },
      7: {
        type: PRINT_STATEMENT,
        input1: 'get_gcd(10, 25)',
      },
    },
    {
      // 5, minimum value in a list
      0: {
        type: FUNCTION_DECLARATION,
        input1: 'get_minimum',
        input2: 'lst',
      },
      1: {
        type: ASSIGNMENT,
        input1: 'minimum',
        input2: "float('inf')",
      },
      2: {
        type: FOR_LOOP,
        input1: 'i',
        input2: 'len(lst)',
      },
      3: {
        type: IF_STATEMENT,
        input1: 'lst[i] < minimum',
      },
      4: {
        type: ASSIGNMENT,
        input1: 'minimum',
        input2: 'lst[i]',
      },
      5: {
        type: RETURN_STATEMENT,
        input1: 'minimum',
      },
      6: {
        type: LIST_INITIALIZATION,
        input1: 'lst',
        input2: '7, 14, 3, 0, 8, 2',
      },
      7: {
        type: PRINT_STATEMENT,
        input1: 'get_minimum(lst)',
      },
    },
    {
      // 6, sum of elements in a list
      0: {
        type: FUNCTION_DECLARATION,
        input1: 'get_total',
        input2: 'lst',
      },
      1: {
        type: ASSIGNMENT,
        input1: 'total',
        input2: '0',
      },
      2: {
        type: FOR_LOOP,
        input1: 'i',
        input2: 'len(lst)',
      },
      3: {
        type: INCREMENT,
        input1: 'total',
        input2: 'lst[i]',
      },
      4: {
        type: RETURN_STATEMENT,
        input1: 'total',
      },
      5: {
        type: LIST_INITIALIZATION,
        input1: 'lst',
        input2: '4, 1, 3, 9',
      },
      6: {
        type: PRINT_STATEMENT,
        input1: 'get_total(lst)',
      },
      7: {
        type: RETURN_STATEMENT,
        input1: 'total',
      },
    },
    {
      // 7, two sum
      8: {
        type: COMMENT,
        input1: 'Find two numbers in the list that add up to a target number.',
      },
      9: {
        type: COMMENT,
        input1: 'O(n^2) solution, where n is the length of the list.',
      },
      0: {
        type: FUNCTION_DECLARATION,
        input1: 'two_sum',
        input2: 'lst, target',
      },
      2: {
        type: FOR_LOOP,
        input1: 'i',
        input2: 'len(lst)',
      },
      3: {
        type: FOR_LOOP,
        input1: 'j',
        input2: 'i',
      },
      4: {
        type: IF_STATEMENT,
        input1: 'lst[i] + lst[j] == target',
      },
      5: {
        type: RETURN_STATEMENT,
        input1: '[lst[i], lst[j]]',
      },
      6: {
        type: LIST_INITIALIZATION,
        input1: 'lst',
        input2: '4, -10, 7, 1, 2',
      },
      7: {
        type: PRINT_STATEMENT,
        input1: 'two_sum(lst, 11)',
      },
      10: {
        type: RETURN_STATEMENT,
        input1: '-1',
      },
    },
    {
      // 8
      1: {
        type: ASSIGNMENT,
        input1: 'N',
        input2: '4',
      },
      2: {
        type: ASSIGNMENT,
        input1: 'curr',
        input2: '1',
      },
      3: {
        type: FOR_LOOP,
        input1: 'i',
        input2: '1, N+1',
      },
      4: {
        type: FOR_LOOP,
        input1: 'j',
        input2: '1, i+1',
      },
      5: {
        type: PRINT_SAME_LINE,
        input1: 'curr',
        input2: ' ',
      },
      6: {
        type: INCREMENT,
        input1: 'curr',
        input2: '1',
      },
      7: {
        type: PRINT_STATEMENT,
        input1: '',
      },
      8: {
        type: COMMENT,
        input1: 'Challenge: print the numbers as below',
      },
      9: {
        type: COMMENT,
        input1: '1',
      },
      10: {
        type: COMMENT,
        input1: '2 3',
      },
      11: {
        type: COMMENT,
        input1: '4 5 6',
      },
      12: {
        type: COMMENT,
        input1: '7 8 9 10',
      },
      13: {
        type: COMMENT,
        input1: 'etc',
      },
      14: {
        type: COMMENT,
        input1: 'There is a trailing whitespace at the end of each line.',
      },
      15: {
        type: COMMENT,
        input1: 'How would you fix it?',
      },
    },
  ][index];

  console.log(index);
  console.log(items, inputs);

  const i = 100000;
  const isCodeToggled = false;
  const fileName = 'factorial';
  const ifCombinations = new Map();

  return { items, inputs, i, isCodeToggled, fileName, ifCombinations };
};
