import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ReactMarkdown from 'react-markdown';

import {
  INCREMENT,
  FUNCTION_DECLARATION,
  ASSIGNMENT,
  RETURN_STATEMENT,
  FOR_LOOP,
  IF_STATEMENT,
  ELIF_STATEMENT,
  ELSE_STATEMENT,
  PRINT_STATEMENT,
  PRINT_SAME_LINE,
  WHILE_LOOP,
  MAP_STATEMENT,
  FILTER_STATEMENT,
  LIST_INITIALIZATION,
  LIST_APPEND,
  LIST_POP,
  LIST_COPY,
  LIST_LENGTH,
  FREE_TEXT,
  BREAK,
  CONTINUE,
  COMMENT,
  FOR_LOOP_ELEMENT,
  DICT_INIT,
  DICT_ADD,
  DICT_POP,
} from './block-types';

const code = (lines) => {
  return (
    <div>
      <SyntaxHighlighter language="python">
        {lines.join('\n')}
      </SyntaxHighlighter>
    </div>
  );
};

const markdown = (text) => {
  return (
    <div style={{ paddingLeft: '1em', color: 'black', fontWeight: 'thin' }}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

const md = (text) => {
  return (
    <div style={{ display: 'inline' }}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};
const HELPBOX_BLOCK = (type) => {
  let lines;
  switch (type) {
    case INCREMENT:
      return <div></div>;
    case ASSIGNMENT:
      lines = [
        `# assign the value 1 to variable count`,
        `count = 1`,
        `# assign the value of the variable y to variable count`,
        `count = y`,
        `# assign (the value of x) + 1 to variable x, i.e. increment x by 1`,
        `x = x + 1`,
        `# shorthand version`,
        `x += 1`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `The assignment operation **"="** takes the value on the right hand side and assigns it to the variable on the left hand side.`,
          )}
          {code(lines)}
        </div>
      );
    case FUNCTION_DECLARATION:
      return <div>FUNCTION_DECLARATION</div>;
    case RETURN_STATEMENT:
      return <div>RETURN_STATEMENT</div>;
    case FOR_LOOP:
      return <div>FOR_LOOP</div>;
    case FOR_LOOP_ELEMENT:
      return <div>FOR_LOOP_ELEMENT</div>;
    case IF_STATEMENT:
      return <div>IF_STATEMENT</div>;
    case ELSE_STATEMENT:
      return <div>ELSE_STATEMENT</div>;
    case ELIF_STATEMENT:
      return <div>ELIF_STATEMENT</div>;
    case PRINT_STATEMENT:
      return <div>PRINT_STATEMENT</div>;
    case PRINT_SAME_LINE:
      return <div>PRINT_SAME_LINE</div>;
    case WHILE_LOOP:
      return <div>WHILE_LOOP</div>;
    case MAP_STATEMENT:
      lines = [
        `lst = [1, 4, 3]`,
        `squared = list(map(lambda num: num**2, lst))`,
        `# remember to use list() to convert the map object to a list`,
        `print(squared) # [1, 16, 9]`,
        ``,
        `# we can also save the result to the same input list`,
        `lst = list(map(lambda num: num + 1, lst))`,
        `print(lst) # [2, 5, 4]`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `The **map** function takes in a sequence* (e.g. Python list) and applies the specified function to each element in the sequence.`,
          )}
          {code(lines)}
          {markdown(
            `*The formal term for sequence is called "iterable" in Python. lists, tuples, strings, sets, dictionaries are possible iterables, i.e. things that you can loop over.`,
          )}
        </div>
      );
    case FILTER_STATEMENT:
      lines = [
        `lst = [2, 10, 7, 8, 9]`,
        `odd_numbers = list(filter(lambda num: num % 2 == 1, lst))`,
        `print(odd_numbers) # [7, 9]`,
        ``,
        `# we can also save the result to the same input list`,
        `lst = list(filter(lambda x: x % 3 == 0, lst))`,
        `print(lst) # [9]`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `The **filter** function takes in a sequence* (e.g. Python list) and returns the elements that satisfy the given condition.`,
          )}
          {code(lines)}
          {markdown(
            `*The formal term for sequence is called "iterable" in Python. lists, tuples, strings, sets, dictionaries are possible iterables, i.e. things that you can loop over.`,
          )}
        </div>
      );
    case LIST_INITIALIZATION:
      lines = [
        `lst = [] # create a new empty list`,
        `lst = [1, 4, 3] # create a new list with three integers`,
        ``,
        `# create a new list with elements of different datatypes`,
        `lst = [1, "hello", 4.5, False, None]`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(`This operation is used to **create** a new list.`)}
          {code(lines)}
        </div>
      );
    case LIST_APPEND:
      lines = [
        `lst = [3, 4]`,
        `lst.append(1) # add 1 to the end of the list`,
        `print(lst) # output: [3, 4, 1]`,
        `# What is the time complexity of list.append(element)?`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `This method **adds** **an element** to the end of the list.`,
          )}
          {code(lines)}
        </div>
      );
    case LIST_POP:
      lines = [
        `lst = [1, 5, 3]`,
        `# remove the last element of the list`,
        `popped = lst.pop()`,
        `print(popped) # output: 3`,
        ``,
        `# What happens if we give an index to lst.pop()?`,
        `popped = lst.pop(1) # try it out!`,
        ``,
        `# What is the time complexity of list.pop()?`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `This method **removes** the element at the end of the list, and returns that element.`,
          )}
          {code(lines)}
        </div>
      );
    case LIST_LENGTH:
      lines = [
        `lst = [1, 7, 9, 3]`,
        `length = len(lst)`,
        `print(length) # output: 4`,
        ``,
        `# compute the sum of all elements in the list`,
        `sum = 0`,
        `for i in range(len(lst)):`,
        `    sum += lst[i]`,
        `print(sum) # output: 20`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `This method returns the **length** (total number of elements) in the list.`,
          )}
          {code(lines)}
        </div>
      );
    case LIST_COPY:
      lines = [
        `lst = [2, 4, 9]`,
        `clone = lst.copy() # deep copy`,
        `bad_list = lst # shallow copy`,
        ``,
        `# What is deep copy and shallow copy?`,
        `lst[1] = 0`,
        `print(lst) # [2, 0, 9]`,
        `print(clone) # [2, 4, 9] - clone is not affected`,
        `print(bad_list) # [2, 0, 9] - bad_list is affected`,
        `# always use deep copy to make a copy of a list!`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(`This method returns a **deep** **copy** of the list.`)}
          {code(lines)}
        </div>
      );
    case FREE_TEXT:
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `This coding block can be anything. Type your own code statement here!`,
          )}
        </div>
      );
    case BREAK:
      lines = [
        `lst = [4, 9, 9]`,
        `target = 9`,
        `for i in range(len(lst)):`,
        `    if lst[i] == target:`,
        `        print(i)`,
        `        break`,
        `# output: 1 (there is no 2)`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(`**break** is used to terminate a loop completely.`)}
          {code(lines)}
        </div>
      );
    case CONTINUE:
      lines = [
        `lst = [4, 9, 9, 7]`,
        `target = 9`,
        `for i in range(len(lst)):`,
        `    if lst[i] == target:`,
        `        continue`,
        `    print(lst[i])`,
        `# output: 4 and 7`,
        `# when lst[i] == target, continue is executed,`,
        `# the loop will skip the rest of the statements and go to the next iteration`,
      ];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `**continue** is used to terminate the **current iteration** of the loop and proceed directly to the next iteration.`,
          )}
          {code(lines)}
        </div>
      );
    case COMMENT:
      lines = [`# this is a comment`, `# this is another comment`];
      return (
        <div style={{ maxWidth: '100%' }}>
          {markdown(
            `Starting a line with **#** to write a comment in Python. Comments will not be executed.`,
          )}
          {code(lines)}
        </div>
      );
    case DICT_INIT:
      return <div>DICT_INIT</div>;
    case DICT_ADD:
      return <div>DICT_ADD</div>;
    case DICT_POP:
      return <div>DICT_POP</div>;

    default:
      return (
        <div>
          {markdown(
            'Hover on any coding blocks on the left to see the helpbox!',
          )}
        </div>
      );
  }
};

export { HELPBOX_BLOCK, md }; // exporting constants
