import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';

class App extends React.Component {
  render() {
    return (
      <div style={{ fontFamily: 'monospace', input: 'monospace' }}>
        <div>
          <Home></Home>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
