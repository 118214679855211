export const INCREMENT = 0; // ["+=", "-=", "*=", "/="]
export const RELATIVE_EXPRESSION = 1; // ['<', '<=', '==', '>=', '>', '!=']
export const FUNCTION_DECLARATION = 2; // ["def f(x):"]
export const ASSIGNMENT = 3; // ["="]
export const RETURN_STATEMENT = 4; // ["return"]
export const AND_OR = 5; // ["and, or"]
export const FOR_LOOP = 6;
export const FOR_LOOP_ELEMENT = -6;
export const IF_STATEMENT = 7;
export const ELIF_STATEMENT = 8;
export const ELSE_STATEMENT = 9;
export const PRINT_STATEMENT = 10;
export const PRINT_SAME_LINE = -10;
export const WHILE_LOOP = 11;
export const BLANK_STATEMENT = 12;
export const MAP_STATEMENT = 13;
export const FILTER_STATEMENT = 14;
export const LIST_INITIALIZATION = 15;
export const LIST_APPEND = 16;
export const LIST_POP = 17;
export const LIST_COPY = 19;
export const LIST_LENGTH = 20;
export const FREE_TEXT = 21;
export const BREAK = 22;
export const CONTINUE = 23;
export const COMMENT = 24;
export const DICT_INIT = 25;
export const DICT_ADD = 26;
export const DICT_POP = 27;
