import React from 'react';

import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import './app.css';
import ReactTooltip from 'react-tooltip';
import Collapsible from 'react-collapsible';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Navbar from 'reactjs-navbar';
import Tour from 'reactour';
import Slide from 'react-reveal/Slide';
import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';
import 'reactjs-navbar/dist/index.css';
import './navigation-bar.css';
import 'react-notifications/lib/notifications.css';
import { HELPBOX_BLOCK, md } from './helpbox';
import { MODEL_BLOCKS, FACTORIAL } from './constants';
import {
  ASSIGNMENT_BLOCK,
  FUNCTION_DECLARATION_BLOCK,
  RETURN_BLOCK,
  FOR_LOOP_BLOCK,
  FOR_LOOP_ELEMENT_BLOCK,
  IF_BLOCK,
  WHILE_BLOCK,
  MAP_BLOCK,
  FILTER_BLOCK,
  PRINT_BLOCK,
  PRINT_SAME_LINE_BLOCK,
  LIST_INITIALIZATION_BLOCK,
  LIST_APPEND_BLOCK,
  LIST_POP_BLOCK,
  LIST_LENGTH_BLOCK,
  BREAK_BLOCK,
  CONTINUE_BLOCK,
  COMMENT_BLOCK,
  INCREMENT_BLOCK,
  LIST_COPY_BLOCK,
  FREE_TEXT_BLOCK,
  getTextInBox,
  getString,
  renderCollapseIcon,
  DICT_INIT_BLOCK,
  DICT_ADD_BLOCK,
  DICT_POP_BLOCK,
} from './elements';
import {
  parseTree,
  getFileOutput,
  handlerStub,
  copyJSON,
  saveFile,
  findPath,
  getStatementNumber,
  removeBlock,
} from './utils';
import {
  INCREMENT,
  FUNCTION_DECLARATION,
  ASSIGNMENT,
  RETURN_STATEMENT,
  FOR_LOOP,
  IF_STATEMENT,
  ELIF_STATEMENT,
  ELSE_STATEMENT,
  PRINT_STATEMENT,
  PRINT_SAME_LINE,
  WHILE_LOOP,
  BLANK_STATEMENT,
  MAP_STATEMENT,
  FILTER_STATEMENT,
  LIST_INITIALIZATION,
  LIST_APPEND,
  LIST_POP,
  LIST_COPY,
  LIST_LENGTH,
  FREE_TEXT,
  BREAK,
  CONTINUE,
  COMMENT,
  FOR_LOOP_ELEMENT,
  DICT_INIT,
  DICT_ADD,
  DICT_POP,
} from './block-types';
import { saveToLocalStorage, loadFromLocalStorage } from './localStorage';
import { loadFromExample } from './examples';

const tourConfig = [
  {
    selector: '[data-tut="everything"',
    content:
      'Welcome to blockPython! This is a tutorial to help you get started with using the platform. Click ➔ to continue.',
  },
  {
    selector: '[data-tut="codeIDE" ]',
    content: `Here is the code section. Your Python coding blocks will be here!`,
  },
  {
    selector: '[data-tut="choosingColumn"]',
    content: md(
      `You can see all the coding blocks available in blockPython here. **Click** on the block to add it to the code section.`,
    ),
  },
  {
    selector: '[data-tut="IDEUtils"]',
    content: () => (
      <div>
        Here are the features provided by the platform. Check it out one by one!
        Click the <b>Examples</b> tab to see code examples written in
        blockPython! (later, not now!)
      </div>
    ),
  },
  {
    selector: '[data-tut="helpbox"]',
    content: `This section provides guidance on how you can use the Python coding blocks on the left.`,
  },
  {
    selector: '[data-tut="codeOutput"]',
    content: `This is the code output session. You can click "Run code" in the navigation bar previously to execute your code.`,
  },
  {
    selector: '[data-tut="IDEUtils"]',
    content: () => (
      <div>
        {' '}
        The tutorial is over. If you want to visit this tutorial again, click
        the <b>Tutorial</b> tab here!
      </div>
    ),
  },
];

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      inputs: {},
      i: 10000,
      isCodeToggled: false,
      fileName: 'Untitled',
      hovering: -1, // hovering block type
      history: [], // array of {inputs, items}
      ifCombinations: new Map(), // map of if combinations
      isTourOpen: false,
      isFirstTime: true, // is first time visiting the website
      isPythonTutorOn: false,
      mouseX: 0,
      mouseY: 0,
    };
  }

  componentDidMount() {
    let contents = loadFromLocalStorage();
    if (contents.items != null && contents.inputs != null) {
      console.log(`${JSON.stringify(contents)} from localStorage`);
      this.setState(contents, () => {
        this.isFirstTimeCheck();
      });
    } else {
      console.log('ERR: Cannot load from localStorage');
      localStorage.clear();
      this.isFirstTimeCheck();
    }
  }

  submitCode = () => {
    const outputString = getFileOutput(
      parseTree({
        items: this.state.items,
        inputs: this.state.inputs,
      }),
    );

    if (outputString.length === 0) {
      this.createNotification();
      return;
    }
  };

  isFirstTimeCheck = () => {
    if (this.state.isFirstTime) {
      // first time visiting the website

      this.setState({ isFirstTime: false });
      this.openTour();
    }
  };

  togglePythonTutor = () => {
    this.setState({
      isPythonTutorOn: !this.state.isPythonTutorOn,
    });
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.loadExamples(6);
    this.setState({ isTourOpen: true });
  };

  loadExamples = (index) => {
    console.log(index);
    let contents = loadFromExample(index);
    if (contents) {
      console.log(contents);
      this.setState({
        items: contents.items,
        inputs: contents.inputs,
        i: contents.i,
        isCodeToggled: contents.isCodeToggled,
        fileName: contents.fileName,
        ifCombinations: contents.ifCombinations,
        isFirstTime: false,
      });
    } else {
      console.log('ERR: Cannot load from example');
    }
  };

  componentDidUpdate() {
    saveToLocalStorage(this.state);
  }

  handleInputChange(event, id, input) {
    const newInputs = copyJSON(this.state.inputs);
    let newValue = event.target.value;

    const MAXIMUM_LENGTH = newInputs[id]['type'] === COMMENT ? 70 : 40;

    if (newValue.length > MAXIMUM_LENGTH) {
      // stop accepting input
      return;
    }
    newInputs[id][input] = newValue;
    this.setState({ inputs: newInputs }, () => {
      if (input === 'operator') {
        return;
      }

      // change input field size
      console.log(document.getElementById(`${id}-${input}`));
      document.getElementById(
        `${id}-${input}`,
      ).style.width = `${this.state.inputs[id][input].length}ch`; // set width according to length of input field
    });
  }

  handleFileNameChange = (event) => {
    this.setState({
      fileName: event.target.value,
    });
  };

  addBlock = (e, type, ifCombinations, isIndent = false, previous = null) => {
    // index in model blocks
    console.log(`Adding ${type}`);
    const newItems = [...this.state.items];
    const newInputs = copyJSON(this.state.inputs);
    let newIndex = this.state.i;

    const newBlock = { id: newIndex };
    if (!isIndent) {
      newItems.push(newBlock);
      newInputs[newIndex] = MODEL_BLOCKS.filter(
        (block) => block.type === type,
      )[0];
    } else {
      let lastBlock = newItems[newItems.length - 1];

      if (lastBlock.hasOwnProperty('children')) {
        lastBlock['children'].push(newBlock);
      } else {
        lastBlock['children'] = [newBlock];
      }

      newInputs[newIndex] = MODEL_BLOCKS.filter(
        (block) => block.type === type,
      )[0];
    }

    if (
      previous != null &&
      (type === ELIF_STATEMENT || type === ELSE_STATEMENT)
    ) {
      for (let i = 0; i < previous.length; i++) {
        if (!ifCombinations.has(previous[i])) {
          ifCombinations.set(previous[i], []);
        }

        if (!ifCombinations.has(newIndex)) {
          ifCombinations.set(newIndex, []);
        }
        ifCombinations.get(newIndex).push(previous[i]);
        ifCombinations.get(previous[i]).push(newIndex);
      }
    }

    console.log(newIndex, `new index`);
    this.setState(
      {
        items: newItems,
        i: newIndex + 2,
        inputs: newInputs,
        ifCombinations: ifCombinations,
      },
      () => {
        if (type === IF_STATEMENT) {
          // if -> also push elif and else
          this.addBlock(
            e,
            ELIF_STATEMENT,
            ifCombinations,
            (isIndent = false),
            (previous = [newIndex]),
          );
        } else if (type === ELIF_STATEMENT) {
          this.addBlock(
            e,
            ELSE_STATEMENT,
            ifCombinations,
            (isIndent = false),
            (previous = previous.concat(newIndex)),
          );
        } else if (type === FUNCTION_DECLARATION) {
          // def -> push return function
          this.addBlock(e, RETURN_STATEMENT, ifCombinations, (isIndent = true));
        } else if (type === WHILE_LOOP) {
          // while -> also push increment
          this.addBlock(e, INCREMENT, ifCombinations, (isIndent = true));
        }
      },
    );
  };

  onDragEnd = (items, ifCombinations) => {
    console.log(ifCombinations);
    let newItems = copyJSON(items.items);

    let dragItem = items.dragItem;
    console.log(dragItem);

    console.log(`onDragEnd`);
    this.setState({ items: newItems });
  };

  onMouseOverStatement = (e, blockType) => {
    if (blockType !== this.state.isHovering) {
      this.setState({ hovering: blockType });
    }
  };

  onDragStartTest = (e) => {
    console.log('onDragstart');
  };

  renderItem = (
    { item, index, depth, collapseIcon },
    tree,
    items,
    inputs,
    is_read_only = false,
  ) => (
    <div
      onDrag={this.onDragStartTest}
      onKeyDown={(e) => this.handleKeyboardPress(e, item, tree, items, inputs)}
      onDragOver={(e) => this.onDragOver(e)}
      onDrop={(e) => {
        this.onDrop(e, item.id);
      }}
    >
      <div
        id={item.id}
        tabIndex="0"
        style={{ display: 'inline-block' }}
        className="separate-item"
      >
        {this.getInputField(item.id, inputs, is_read_only)}
      </div>
      <div style={{ display: 'inline', float: 'right' }}>{collapseIcon}</div>
    </div>
  );

  renderItemDisplay = (item, inputs, items) => {
    console.log(item, inputs);

    let COLOR = 160;
    return (
      <div>
        <div id={item.id} tabIndex="0" style={{ display: 'inline-block' }}>
          {this.getInputField(item.item.id, inputs, true)}{' '}
          {/* true for read-only */}
        </div>
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            color: `rgb(${COLOR}, ${COLOR}, ${COLOR})`,
          }}
        >
          {1 + getStatementNumber(parseTree({ items, inputs }), item.item.id)}
        </div>
      </div>
    );
  };

  getInputField = (id, inputs, is_read_only = false) => {
    let fields = inputs[id];
    if (fields === undefined) {
      return <div>Null</div>;
    }
    let blockType = inputs[id]['type'];

    let input1_div = null;
    let input2_div = null;
    let input3_div = null;
    let input4_div = null;

    input1_div = (
      <div style={{ display: 'inline-block', width: '1vw !important' }}>
        <input
          id={`${id}-input1`}
          size={20}
          value={inputs[id]['input1']}
          onChange={(e) => {
            if (!is_read_only) {
              this.handleInputChange(e, id, 'input1');
            } else {
              return;
            }
          }}
          style={{
            width: `${
              inputs[id].hasOwnProperty('input1')
                ? inputs[id]['input1'].length + 'ch'
                : ''
            }`,
            fontFamily: 'monospace',
            fontSize: '14px',
          }}
        />
      </div>
    );

    input2_div = (
      <input
        id={`${id}-input2`}
        value={inputs[id]['input2']}
        onChange={(e) => {
          if (!is_read_only) {
            this.handleInputChange(e, id, 'input2');
          } else {
            return;
          }
        }}
        style={{
          width: `${
            inputs[id].hasOwnProperty('input2')
              ? inputs[id]['input2'].length + 'ch'
              : ''
          }`,
          fontFamily: 'monospace',
          fontSize: '14px',
        }}
      />
    );

    input3_div = (
      <input
        id={`${id}-input3`}
        value={inputs[id]['input3']}
        onChange={(e) => {
          if (!is_read_only) {
            this.handleInputChange(e, id, 'input3');
          } else {
            return;
          }
        }}
        style={{
          width: `${
            inputs[id].hasOwnProperty('input3')
              ? inputs[id]['input3'].length + 'ch'
              : ''
          }`,
          fontFamily: 'monospace',
          fontSize: '14px',
        }}
      />
    );

    input4_div = (
      <input
        id={`${id}-input4`}
        value={inputs[id]['input4']}
        onChange={(e) => {
          if (!is_read_only) {
            this.handleInputChange(e, id, 'input4');
          } else {
            return;
          }
        }}
        style={{
          width: `${
            inputs[id].hasOwnProperty('input4')
              ? inputs[id]['input4'].length + 'ch'
              : ''
          }`,
          fontFamily: 'monospace',
          fontSize: '14px',
        }}
      />
    );

    if (blockType === COMMENT && !is_read_only) {
      input1_div = (
        <div style={{ display: 'inline-block', width: '1vw !important' }}>
          <input
            id={`${id}-input1`}
            size={20}
            value={inputs[id]['input1']}
            onChange={(e) => this.handleInputChange(e, id, 'input1')}
            style={{
              width: `${
                inputs[id].hasOwnProperty('input1')
                  ? inputs[id]['input1'].length + 'ch'
                  : ''
              }`,
              fontFamily: 'monospace',
              fontSize: '14px',
              color: 'rgb(153, 153, 153)',
            }}
          />
        </div>
      );
    } else if (blockType === COMMENT && is_read_only) {
      input1_div = (
        <div style={{ display: 'inline-block', width: '1vw !important' }}>
          <input
            type="view"
            disabled
            id={`${id}-input1`}
            size={20}
            value={inputs[id]['input1']}
            style={{
              width: `${
                inputs[id].hasOwnProperty('input1')
                  ? inputs[id]['input1'].length + 'ch'
                  : ''
              }`,
              fontFamily: 'monospace',
              fontSize: '14px',
              color: 'rgb(153, 153, 153)',
            }}
          />
        </div>
      );
    }

    switch (fields['type']) {
      case INCREMENT:
        return (
          <div>
            {input1_div}
            &nbsp;
            {getString('+=')}
            &nbsp;
            {input2_div}
          </div>
        );
      case ASSIGNMENT:
        return (
          <div>
            {input1_div}
            &nbsp;{getString('=')}&nbsp;
            {input2_div}
          </div>
        );
      case FUNCTION_DECLARATION: // function declaration, def function_name(inputs):
        return (
          <div>
            {getTextInBox('def')}&nbsp;
            {input1_div}
            {getString('(')}
            {input2_div}
            {getString(')')}
            {getString(':')}
          </div>
        );
      case RETURN_STATEMENT: // return
        return (
          <div>
            {getTextInBox('return')}&nbsp;
            {input1_div}
          </div>
        );
      case FOR_LOOP: // for i in range
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getTextInBox('for')}&nbsp;{input1_div}&nbsp;
            </div>
            {getString('in')}
            <div style={{ display: 'inline-block' }}> &nbsp; </div>
            {getString('range')}

            <div style={{ display: 'inline-block' }}>
              {getString('(')}
              {input2_div}
              {getString(')')}
              {getString(':')}
            </div>
          </div>
        );
      case FOR_LOOP_ELEMENT: // for i in range
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getTextInBox('for')}&nbsp;{input1_div}&nbsp;
            </div>
            {getString('in')}&nbsp;
            {input2_div}
            {getString(':')}
          </div>
        );

      case IF_STATEMENT:
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getTextInBox('if')}&nbsp;{getString('(')}
              {input1_div}
              {getString(')')}
              {getString(':')}
            </div>
          </div>
        );
      case ELIF_STATEMENT:
        return (
          <div>
            {getTextInBox('elif')}&nbsp;{getString('(')}
            {input1_div}
            {getString(')')}
            {getString(':')}
          </div>
        );
      case ELSE_STATEMENT:
        return (
          <div>
            <div style={{ display: 'inline-block' }}>
              {getTextInBox('else')}
            </div>
            {getString(':')}
          </div>
        );
      case PRINT_STATEMENT: // print
        return (
          <div>
            {getTextInBox('print')}
            {getString('(')}
            {input1_div}
            {getString(')')}
          </div>
        );
      case PRINT_SAME_LINE: // print
        // color in the middle no need to put in input block
        return (
          <div>
            {getTextInBox('print')}
            {getString('(')}
            {input1_div}
            {getString(',')} end {getString('=')} {getString("'")}
            {input2_div}
            {getString("'")}
            {getString(')')}
          </div>
        );
      case WHILE_LOOP: // while
        return (
          <div>
            {getTextInBox('while')}&nbsp;{getString('(')}
            {input1_div}
            {getString(')')}
            {getString(':')}
          </div>
        );
      case BLANK_STATEMENT:
        return <div>&nbsp;</div>;
      case MAP_STATEMENT:
        return (
          <div>
            {input4_div} = {getString('list')}
            {getString('(')}
            {getString('map')}
            {getString('(')}
            {getString('lambda')} {input1_div}: {input2_div}, {input3_div}
            {getString(')')}
            {getString(')')}
          </div>
        );
      case FILTER_STATEMENT:
        return (
          <div>
            {input4_div} = {getString('list')}
            {getString('(')}
            {getString('filter')}
            {getString('(')}
            {getString('lambda')} {input1_div}: {input2_div}, {input3_div}
            {getString(')')}
            {getString(')')}
          </div>
        );
      case LIST_INITIALIZATION:
        return (
          <div>
            {input1_div} {getString('=')} {getString('[')}
            {input2_div}
            {getString(']')}
          </div>
        );
      case LIST_APPEND:
        return (
          <div>
            {input1_div}.append{getString('(')}
            {input2_div}
            {getString(')')}
          </div>
        );
      case LIST_POP:
        return (
          <div>
            {input1_div} {getString('=')} {input2_div}
            {getString('.')}pop{getString('(')}
            {getString(')')}
          </div>
        );
      case LIST_COPY:
        return (
          <div>
            {input1_div} {getString('=')} {input2_div}
            {getString('.')}copy{getString('(')}
            {getString(')')}
          </div>
        );
      case LIST_LENGTH:
        return (
          <div>
            {input1_div} {getString('=')} {getString('len')}
            {getString('(')}
            {input2_div}
            {getString(')')}
          </div>
        );
      case FREE_TEXT:
        return <div>{input1_div}</div>;
      case BREAK:
        return <div>{getTextInBox('break')}</div>;
      case CONTINUE:
        return <div>{getTextInBox('continue')}</div>;
      case COMMENT:
        return (
          <div>
            {getTextInBox('#')} {input1_div}
          </div>
        );
      case DICT_INIT: // `${input1} = {${input2}}`
        return (
          <div>
            {input1_div} {getString('=')} {getString('{')}
            {input2_div}
            {getString('}')}
          </div>
        );
      case DICT_ADD: // `${input1}[${input2}] = ${input3}`
        return (
          <div>
            {input1_div}
            {getString('[')}
            {input2_div}
            {getString(']')} {getString('=')} {input3_div}
          </div>
        );
      case DICT_POP: // `${input3} = ${input1}.pop(${input2})`
        return (
          <div>
            {input3_div} {getString('=')} {input1_div}
            {getString('.')}pop{getString('(')}
            {input2_div}
            {getString(')')}
          </div>
        );
      default:
        return (
          <div>
            <h1>Error Element not found</h1>
          </div>
        );
    }
  };

  handleKeyboardPress = (e, item, tree, items, inputs) => {
    /*
        Current block remove will remove all corresponding children
        To-do: attach the remaining childrens to the preceding block
        */
    let button = e.which;
    console.log(`some button pressed ${button}`);
    const DELETE = 46;
    const UP = 38;
    const DOWN = 40;
    if (button === DELETE) {
      //
      console.log('Delete button pressed');

      // // remove from list of items and inputs
      let newState = removeBlock(items, inputs, item.id);
      this.setState(newState);
    } else if (button === UP) {
      console.log('UP');
      // const newItems = swapBlocks(item.id, getPrevBlockId(item.id, tree));
    } else if (button === DOWN) {
      console.log('down');
      // const newItems = swapBlocks(item.id, getNextBlockId(item.id, tree), items);
    }
  };

  onDragOver = (e) => {
    // allows this element to be dragged over by other elements
    e.preventDefault();
  };

  onDrop = (e, block_id) => {
    // code for dragging logical statement (case 5) and drop to if statement
    let blockType = parseInt(e.dataTransfer.getData('blockType'), 10);
    let newItems = copyJSON(this.state.items);
    let newInputs = copyJSON(this.state.inputs);

    console.log(`Need to add block ${blockType} to item id ${block_id}`);
    const path = findPath(newItems, block_id);
    console.log(path);

    let curr = newItems;
    for (let i = 0; i < path.length - 1; i++) {
      curr = curr[path[i]]['children'];
    }
    let newIndex = this.state.i;
    curr.splice(path[path.length - 1] + 1, 0, { id: newIndex }); // add block after the block that was dragged on
    newInputs[newIndex] = MODEL_BLOCKS.filter(
      (block) => block.type === blockType,
    )[0];

    this.setState({ items: newItems, inputs: newInputs, i: newIndex + 1 });
  };

  onDragStart = (e, blockType) => {
    e.dataTransfer.setData('blockType', parseInt(blockType, 10));
  };

  onSaveFile = () => {
    saveFile(this.state);
  };

  handleExampleChange = (e) => {
    // arrow functions to avoid binding this
    console.log('Yay');

    this.setState({
      items: FACTORIAL.items,
      inputs: FACTORIAL.inputs,
    });
  };

  undo = (e) => {
    // history = [..., prev_history, current_history]
    if (this.state.history.length <= 1) {
      // cannot undo since we only have current history
      console.log('Cannot undo');
      return;
    }

    this.state.history.pop(); // pop the current history
    let prevHistory = this.state.history.pop(); // pop the previous history
    this.setState({
      items: prevHistory.items,
      inputs: prevHistory.inputs,
    });
  };

  createNotification = () => {
    console.log('Clicked');
    const TIMEOUT = 10000;
    // delete existing notification if exists
    if (document.getElementsByClassName('notification-message').length >= 1) {
      // existing message is still there
      document.getElementsByClassName('notification-message')[0].click();
    }
    const EMPTY_CODE = `You have not entered any code. Please enter some code to start running!`;
    NotificationManager.error(EMPTY_CODE, 'Error', TIMEOUT, null, true);
  };

  toggleCode = () => {
    this.setState({ isCodeToggled: !this.state.isCodeToggled });
  };
  render() {
    console.log(this.state.items);
    console.log(this.state.inputs);
    let currentState = { items: this.state.items, inputs: this.state.inputs };
    this.state.history.push(currentState);

    const MAXIMUM_HISTORY_LENGTH = 100;
    if (this.state.history.length > MAXIMUM_HISTORY_LENGTH) {
      this.state.history.shift(); // remove first element in history (FIFO)
    }

    const choosingColumn = (
      <div
        style={{
          maxHeight: '100vh',
          minHeight: '100vh',
          overflowY: 'scroll',
          overflowX: 'scroll',
          minWidth: '100%',
          maxWidth: '100%',
        }}
      >
        <Collapsible open={true} trigger="Functions">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, FUNCTION_DECLARATION, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) =>
              this.onMouseOverStatement(e, FUNCTION_DECLARATION)
            }
            onDragStart={(e) => this.onDragStart(e, FUNCTION_DECLARATION)}
          >
            {FUNCTION_DECLARATION_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, RETURN_STATEMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, RETURN_STATEMENT)}
            onDragStart={(e) => this.onDragStart(e, RETURN_STATEMENT)}
          >
            {RETURN_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="if statements">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, IF_STATEMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, IF_STATEMENT)}
            onDragStart={(e) => this.onDragStart(e, IF_STATEMENT)}
          >
            {IF_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="print statements">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, PRINT_STATEMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, PRINT_STATEMENT)}
            onDragStart={(e) => this.onDragStart(e, PRINT_STATEMENT)}
          >
            {PRINT_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, PRINT_SAME_LINE, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, PRINT_SAME_LINE)}
            onDragStart={(e) => this.onDragStart(e, PRINT_SAME_LINE)}
          >
            {PRINT_SAME_LINE_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="for and while loop">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, FOR_LOOP, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, FOR_LOOP)}
            onDragStart={(e) => this.onDragStart(e, FOR_LOOP)}
          >
            {FOR_LOOP_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, FOR_LOOP_ELEMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, FOR_LOOP_ELEMENT)}
            onDragStart={(e) => this.onDragStart(e, FOR_LOOP_ELEMENT)}
          >
            {FOR_LOOP_ELEMENT_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, WHILE_LOOP, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, WHILE_LOOP)}
            onDragStart={(e) => this.onDragStart(e, WHILE_LOOP)}
          >
            {WHILE_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) => this.addBlock(e, BREAK, this.state.ifCombinations)}
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, BREAK)}
            onDragStart={(e) => this.onDragStart(e, BREAK)}
          >
            {BREAK_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, CONTINUE, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, CONTINUE)}
            onDragStart={(e) => this.onDragStart(e, CONTINUE)}
          >
            {CONTINUE_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="Assignment statements">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, ASSIGNMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, ASSIGNMENT)}
            onDragStart={(e) => this.onDragStart(e, ASSIGNMENT)}
          >
            {ASSIGNMENT_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, INCREMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, INCREMENT)}
            onDragStart={(e) => this.onDragStart(e, INCREMENT)}
          >
            {INCREMENT_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="list operations">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, LIST_INITIALIZATION, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) =>
              this.onMouseOverStatement(e, LIST_INITIALIZATION)
            }
            onDragStart={(e) => this.onDragStart(e, LIST_INITIALIZATION)}
          >
            {LIST_INITIALIZATION_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, LIST_APPEND, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, LIST_APPEND)}
            onDragStart={(e) => this.onDragStart(e, LIST_APPEND)}
          >
            {LIST_APPEND_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, LIST_POP, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, LIST_POP)}
            onDragStart={(e) => this.onDragStart(e, LIST_POP)}
          >
            {LIST_POP_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, LIST_COPY, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, LIST_COPY)}
            onDragStart={(e) => this.onDragStart(e, LIST_COPY)}
          >
            {LIST_COPY_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, LIST_LENGTH, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, LIST_LENGTH)}
            onDragStart={(e) => this.onDragStart(e, LIST_LENGTH)}
          >
            {LIST_LENGTH_BLOCK}
          </div>
        </Collapsible>
        <div style={{ overFlowX: 'scroll' }}>
          <Collapsible open={true} trigger="map and filter">
            <div
              style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
              onClick={(e) =>
                this.addBlock(e, MAP_STATEMENT, this.state.ifCombinations)
              }
              draggable="true"
              onMouseOver={(e) => this.onMouseOverStatement(e, MAP_STATEMENT)}
              onDragStart={(e) => this.onDragStart(e, MAP_STATEMENT)}
            >
              {MAP_BLOCK}
            </div>
            <div
              style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
              onClick={(e) =>
                this.addBlock(e, FILTER_STATEMENT, this.state.ifCombinations)
              }
              draggable="true"
              onMouseOver={(e) =>
                this.onMouseOverStatement(e, FILTER_STATEMENT)
              }
              onDragStart={(e) => this.onDragStart(e, FILTER_STATEMENT)}
            >
              {FILTER_BLOCK}
            </div>
          </Collapsible>
        </div>
        <Collapsible open={true} trigger="Dictionary operations">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, DICT_INIT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, DICT_INIT)}
            onDragStart={(e) => this.onDragStart(e, DICT_INIT)}
          >
            {DICT_INIT_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, DICT_ADD, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, DICT_ADD)}
            onDragStart={(e) => this.onDragStart(e, DICT_ADD)}
          >
            {DICT_ADD_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, DICT_POP, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, DICT_POP)}
            onDragStart={(e) => this.onDragStart(e, DICT_POP)}
          >
            {DICT_POP_BLOCK}
          </div>
        </Collapsible>
        <Collapsible open={true} trigger="Others">
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, COMMENT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, COMMENT)}
            onDragStart={(e) => this.onDragStart(e, COMMENT)}
          >
            {COMMENT_BLOCK}
          </div>
          <div
            style={{ display: 'block', padding: '0.5vh 0.5vw 0.5vh 0vw' }}
            onClick={(e) =>
              this.addBlock(e, FREE_TEXT, this.state.ifCombinations)
            }
            draggable="true"
            onMouseOver={(e) => this.onMouseOverStatement(e, FREE_TEXT)}
            onDragStart={(e) => this.onDragStart(e, FREE_TEXT)}
          >
            {FREE_TEXT_BLOCK}
          </div>
        </Collapsible>
      </div>
    );

    var tree = parseTree({
      items: this.state.items,
      inputs: this.state.inputs,
    });

    const codeIDE = (
      <div>
        <div className="module-border-wrap">
          <div className="module">
            <div
              className="code-section"
              style={{
                maxHeight: '90vh',
                minHeight: '90vh',
                overflowY: 'scroll',
              }}
            >
              <Nestable
                items={this.state.items}
                renderItem={(item) =>
                  this.renderItem(
                    item,
                    tree,
                    this.state.items,
                    this.state.inputs,
                  )
                }
                onChange={(items) =>
                  this.onDragEnd(items, this.state.ifCombinations)
                }
                collapsed={false}
                renderCollapseIcon={(obj) => renderCollapseIcon(obj)}
              />
            </div>
          </div>
        </div>
      </div>
    );
    const codeIDE_disabled = (
      <div>
        <div className="module-border-wrap">
          <div className="module">
            <div
              className="code-section"
              style={{
                maxHeight: '90vh',
                minHeight: '90vh',
                overflowY: 'scroll',
              }}
            >
              <Nestable
                items={this.state.items}
                renderItem={(item) =>
                  this.renderItemDisplay(
                    item,
                    this.state.inputs,
                    this.state.items,
                  )
                }
                onChange={(items) =>
                  this.onDragEnd(items, this.state.ifCombinations)
                }
                collapsed={false}
                renderCollapseIcon={(obj) => renderCollapseIcon(obj)}
                handler={handlerStub}
              />
            </div>
          </div>
        </div>
      </div>
    );

    const IDEUtils = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <Navbar
            menuItems={[
              {
                title: !this.state.isPythonTutorOn ? 'Run code' : 'Edit code',
                icon: !this.state.isPythonTutorOn ? <div></div> : <div></div>,
                isAuth: true,
                onClick: () => {
                  if (!this.state.isPythonTutorOn) {
                    this.submitCode();
                  }
                  this.togglePythonTutor();
                },
              },
              {
                title: 'Examples',
                icon: <div></div>,
                isAuth: true,
                subItems: [
                  {
                    title: 'factorial',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(1);

                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'fibonacci',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(0);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'FizzBuzz',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(2);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'map and filter',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(3);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'Compute GCD with while loop',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(4);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'Minimum value in a list',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(5);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'Sum of elements in a list',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(6);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'Find two numbers that sum to target',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(7);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                  {
                    title: 'print and print in same line',
                    isAuth: true,
                    onClick: () => {
                      this.loadExamples(8);
                      if (this.state.isPythonTutorOn) {
                        this.togglePythonTutor();
                      }
                    },
                  },
                ],
              },

              {
                title: 'Undo',
                icon: <div></div>,
                isAuth: true,
                onClick: () => {
                  if (this.state.isPythonTutorOn) {
                    this.togglePythonTutor();
                  }
                  this.undo();
                },
              },
              {
                title: 'Save code',
                icon: <div></div>,
                isAuth: true,
                onClick: () => {
                  this.onSaveFile();
                },
              },
              {
                title: 'Tutorial',
                icon: <div></div>,
                isAuth: true,
                onClick: () => {
                  if (this.state.isPythonTutorOn) {
                    this.togglePythonTutor();
                  }
                  this.openTour();
                },
              },
            ]}
          ></Navbar>
          <ReactTooltip
            backgroundColor={'00BBFF'}
            textColor={'white'}
            delayShow={300}
          />
        </div>
      </div>
    );

    console.log(parseTree(this.state));

    let iFrame = null;
    if (this.state.isPythonTutorOn) {
      const outputString = getFileOutput(
        parseTree({
          items: this.state.items,
          inputs: this.state.inputs,
        }),
      );

      let encodedString = encodeURIComponent(outputString);
      // encodeURIComponent() will not encode A-Z a-z 0-9 - _ . ! ~ * ' ( )

      let ESCAPE = {
        '-': '%2D',
        _: '%5F',
        '.': '%2E',
        '!': '%21',
        '~': '%7E',
        '*': '%2A',
        "'": '%27',
        '(': '%28',
        ')': '%29',
      };
      for (var char in ESCAPE) {
        encodedString = encodedString.replaceAll(char, ESCAPE[char]);
      }

      console.log(encodedString);

      let iFrameSrc = `https://pythontutor.com/iframe-embed.html#code=${encodedString}&codeDivHeight=400&codeDivWidth=350&cumulative=false&curInstr=0&heapPrimitives=nevernest&origin=opt-frontend.js&py=3&rawInputLstJSON=%5B%5D&textReferences=false`;

      iFrame = (
        <div>
          <iframe frameborder="0" src={iFrameSrc} title="Python Tutor">
            {' '}
          </iframe>
        </div>
      );
    }

    const normalDiv = (
      <div data-tut="everything">
        {' '}
        {/* style={{ border: '0.35vh groove #00BBFF' }}} */}
        <div className="flex-container">
          <Slide left>
            <div
              data-tut="choosingColumn"
              style={{ flex: '18%', padding: '0.5vh 0.5vw 0 0.5vw' }}
            >
              {choosingColumn}
            </div>
          </Slide>
          <div
            style={{ flex: '40%', padding: '0.5vh 0.5vw 0 0.5vw' }}
            className="vertical-flex-container"
          >
            <div data-tut="IDEUtils">{IDEUtils}</div>
            <div
              data-tut="codeIDE"
              style={{
                flex: '90%',
                overflowX: 'scroll !important',
                overflowY: 'scroll !important',
              }}
            >
              {this.state.isTourOpen ? codeIDE : codeIDE}
            </div>
          </div>
          <Slide right>
            <div style={{ flex: '30%' }} className="vertical-flex-container">
              <div
                data-tut="helpbox"
                style={{
                  flexGrow: '1',
                  minWidth: '33vw',
                  maxWidth: '33vw',
                  overflowY: 'scroll',
                  overflowX: 'scroll',
                  borderLeft: '2px solid #D3D3D3',
                  borderRight: '2px solid #D3D3D3',
                  borderRadius: '5px',
                }}
                className="helpbox"
              >
                {this.state.isTourOpen
                  ? HELPBOX_BLOCK(LIST_COPY)
                  : HELPBOX_BLOCK(this.state.hovering)}
              </div>
              <div>
                <Tour
                  onRequestClose={this.closeTour}
                  steps={tourConfig}
                  isOpen={this.state.isTourOpen}
                  classname="tour-css"
                />
              </div>
              <div>
                <NotificationContainer enterTimeout={500} leaveTimeout={0} />
              </div>
            </div>
          </Slide>
        </div>
      </div>
    );

    const pythonTutorDiv = (
      <div data-tut="everything" style={{ display: 'flex' }}>
        {' '}
        {/* style={{ border: '0.35vh groove #00BBFF' }}}
         */}
        <div
          style={{ flex: '30%', padding: '0.5vh 0.5vw 0 0.5vw' }}
          className="vertical-flex-container"
        >
          <Slide left>
            <div data-tut="IDEUtils">{IDEUtils}</div>

            <div
              data-tut="codeIDE"
              style={{
                flex: '90%',
                overflowX: 'scroll !important',
                overflowY: 'scroll !important',
              }}
            >
              {this.state.isTourOpen ? codeIDE_disabled : codeIDE_disabled}
            </div>
          </Slide>
        </div>
        <div style={{ flex: '70%' }}>
          <Slide top>{iFrame}</Slide>
        </div>
      </div>
    );
    return (
      <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
        <Slide top>
          {!this.state.isPythonTutorOn ? normalDiv : pythonTutorDiv}
        </Slide>

        <div>
          <CustomCursor
            dimensions={40}
            fill="#FFF"
            smoothness={{
              movement: 0.2,
              scale: 0.1,
              opacity: 0.1,
            }}
          />
        </div>
      </div>
    );
  }
}
