import {
  INCREMENT,
  RELATIVE_EXPRESSION,
  FUNCTION_DECLARATION,
  ASSIGNMENT,
  RETURN_STATEMENT,
  AND_OR,
  FOR_LOOP,
  IF_STATEMENT,
  ELIF_STATEMENT,
  ELSE_STATEMENT,
  PRINT_STATEMENT,
  PRINT_SAME_LINE,
  WHILE_LOOP,
  BLANK_STATEMENT,
  MAP_STATEMENT,
  FILTER_STATEMENT,
  LIST_INITIALIZATION,
  LIST_APPEND,
  LIST_COPY,
  LIST_LENGTH,
  LIST_POP,
  FREE_TEXT,
  BREAK,
  CONTINUE,
  COMMENT,
  FOR_LOOP_ELEMENT,
  DICT_INIT,
  DICT_ADD,
  DICT_POP,
} from './block-types';

export const TREE = {
  items: [
    { id: 0 },
    {
      id: 1,
      children: [
        { id: 2 },
        {
          id: 5,
          children: [{ id: 6 }],
        },
      ],
    },
    { id: 3 },
    { id: 4 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
  ],
  inputs: {
    0: {
      type: INCREMENT,
      input1: 'x',
      input2: '1',
      operator: '+=',
    },
    1: {
      type: RELATIVE_EXPRESSION,
      input1: 'x',
      input2: '1',
      operator: '<=',
    },
    2: {
      type: INCREMENT,
      input1: 'x',
      input2: '1',
      operator: '+=',
    },
    3: {
      type: RELATIVE_EXPRESSION,
      input1: 'x',
      input2: '1',
      operator: '>',
    },
    4: {
      type: FUNCTION_DECLARATION, // def
      input1: 'count',
      input2: 'x,y,z',
    },
    5: {
      type: RELATIVE_EXPRESSION,
      input1: 'x',
      input2: '1',
      operator: '>',
    },
    6: {
      type: RELATIVE_EXPRESSION,
      input1: 'x',
      input2: '1',
      operator: '>',
    },
    7: {
      type: ASSIGNMENT, // assignment
      input1: 'x',
      input2: '1',
    },
    8: {
      type: RETURN_STATEMENT, // return
      input1: 'x',
    },
    9: {
      type: AND_OR, // and/or
      input1: 'x',
      input2: 'y',
      operator: 'and',
    },
    10: {
      type: FOR_LOOP, // for i in range():
      input1: 'i',
      input2: '1, n',
    },
    11: {
      type: IF_STATEMENT, // if
      input1: 'condition',
    },
    12: {
      type: ELIF_STATEMENT, // elif
      input1: 'condition',
    },
    13: {
      // else
      type: ELSE_STATEMENT,
    },
    14: {
      type: RELATIVE_EXPRESSION,
      input1: 'i',
    },
  },
};

export const BLOCK = {
  type: RELATIVE_EXPRESSION,
  input1: 'x',
  input2: '1',
  operator: '<=',
};

export const MODEL_BLOCKS = [
  {
    type: FUNCTION_DECLARATION,
    input1: 'count',
    input2: 'x,y,z',
  },
  {
    type: RETURN_STATEMENT,
    input1: 'num',
  },
  {
    type: IF_STATEMENT,
    input1: 'cond',
  },
  {
    type: ELIF_STATEMENT,
    input1: 'cond',
  },
  {
    type: ELSE_STATEMENT,
  },
  {
    type: FOR_LOOP,
    input1: 'i',
    input2: '1,n+1',
  },
  {
    type: FOR_LOOP_ELEMENT,
    input1: 'num',
    input2: 'lst',
  },

  {
    type: WHILE_LOOP,
    input1: 'i < n',
  },

  {
    type: ASSIGNMENT,
    input1: 'x',
    input2: 'x + 1',
  },
  {
    type: INCREMENT,
    input1: 'i',
    input2: '1',
    operator: '+=',
  },
  {
    type: RELATIVE_EXPRESSION,
    input1: 'x',
    input2: '100',
    operator: '<',
  },
  {
    type: AND_OR,
    input1: 'x == 0',
    input2: 'y == 0',
    operator: 'and',
  },
  {
    type: BLANK_STATEMENT,
    input1: '',
  },
  {
    type: PRINT_STATEMENT,
    input1: 'x',
  },
  {
    type: PRINT_SAME_LINE,
    input1: 'x',
    input2: ',',
  },
  {
    type: MAP_STATEMENT,
    input1: 'x, y',
    input2: 'x * y',
    input3: 'lst',
    input4: 'mapped_lst',
  },
  {
    type: FILTER_STATEMENT,
    input1: 'x, y',
    input2: 'x * y',
    input3: 'lst',
    input4: 'filtered_lst',
  },
  {
    type: LIST_INITIALIZATION,
    input1: 'lst',
    input2: '',
  },
  {
    type: LIST_APPEND,
    input1: 'lst',
    input2: 'x',
  },
  {
    type: LIST_COPY,
    input1: 'lst',
    input2: 'copy_lst',
  },
  {
    type: LIST_LENGTH,
    input1: 'lst',
    input2: 'x',
  },
  {
    type: LIST_POP,
    input1: 'element',
    input2: 'lst',
  },
  {
    type: LIST_LENGTH,
    input1: 'length',
    input2: 'lst',
  },
  {
    type: FREE_TEXT,
    input1: 'print("Hello World")',
  },
  {
    type: BREAK,
  },
  {
    type: CONTINUE,
  },
  {
    type: COMMENT,
    input1: 'comment here',
  },
  {
    type: DICT_INIT,
    input1: 'dictionary',
    input2: '',
  },
  {
    type: DICT_ADD,
    input1: 'dictionary',
    input2: 'key',
    input3: 'value',
  },
  {
    type: DICT_POP,
    input1: 'dictionary',
    input2: 'key',
    input3: 'popped',
  },
];

export const SHORT_TREE = {
  items: [
    {
      id: 0,
      children: [{ id: 1 }],
    },
  ],
  inputs: {
    0: {
      type: FOR_LOOP, // for i in range():
      input1: 'i',
      input2: '1, 4',
    },
    1: {
      type: RELATIVE_EXPRESSION,
      input1: 'i',
    },
  },
};

export const SHORT_PROGRAM = {
  items: [
    { id: 0 },
    { id: 1 },
    {
      id: 2,
      children: [
        {
          id: 5,
          children: [{ id: 3 }],
        },
        {
          id: 6,
          children: [{ id: 7 }],
        },
        {
          id: 8,
          children: [{ id: 9 }],
        },
      ],
    },
    { id: 4 },
  ],
  inputs: {
    0: {
      type: ASSIGNMENT,
      input1: 'total',
      input2: '0',
    },
    1: {
      type: ASSIGNMENT,
      input1: 'N',
      input2: '10',
    },
    2: {
      type: FOR_LOOP, // for i in range():
      input1: 'i',
      input2: 'N',
    },
    3: {
      type: INCREMENT,
      input1: 'total',
      input2: 'i',
      operator: '+=',
    },
    4: {
      type: RELATIVE_EXPRESSION,
      input1: 'total',
    },
    5: {
      type: IF_STATEMENT,
      input1: 'i % 2 == 0',
    },
    6: {
      type: ELIF_STATEMENT,
      input1: 'i % 3 == 0',
    },
    7: {
      type: INCREMENT,
      input1: 'total',
      input2: '3*i',
      operator: '+=',
    },
    8: {
      type: ELSE_STATEMENT,
    },
    9: {
      type: INCREMENT,
      input1: 'total',
      input2: '1',
      operator: '-=',
    },
  },
};

export const FACTORIAL = {
  items: [
    {
      id: 10006,
      children: [
        {
          id: 10008,
          children: [
            {
              id: 10011,
              children: [],
            },
          ],
        },
        {
          id: 10007,
          children: [],
        },
      ],
    },
  ],
  inputs: {
    0: {
      type: 3,
      input1: 'total',
      input2: '0',
    },
    1: {
      type: 3,
      input1: 'N',
      input2: '10',
    },
    2: {
      type: 6,
      input1: 'i',
      input2: 'N',
    },
    3: {
      type: 0,
      input1: 'total',
      input2: 'i',
      operator: '+=',
    },
    4: {
      type: 10,
      input1: 'total',
    },
    5: {
      type: 7,
      input1: 'i % 2 == 0',
    },
    6: {
      type: 8,
      input1: 'i % 3 == 0',
    },
    7: {
      type: 0,
      input1: 'total',
      input2: '3*i',
      operator: '+=',
    },
    8: {
      type: 9,
    },
    9: {
      type: 0,
      input1: 'total',
      input2: '1',
      operator: '-=',
    },
    10006: {
      type: 2,
      input1: 'factorial',
      input2: 'n',
    },
    10007: {
      type: 4,
      input1: 'n * factorial(n-1)',
    },
    10008: {
      type: 7,
      input1: 'n == 0',
    },
    10009: {
      type: 8,
      input1: 'cond',
    },
    10010: {
      type: 9,
    },
    10011: {
      type: 4,
      input1: '1',
    },
  },
};
