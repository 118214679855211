import React from 'react';
import FileSaver from 'file-saver';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import {
  INCREMENT,
  FUNCTION_DECLARATION,
  ASSIGNMENT,
  RETURN_STATEMENT,
  FOR_LOOP,
  IF_STATEMENT,
  ELIF_STATEMENT,
  ELSE_STATEMENT,
  PRINT_STATEMENT,
  PRINT_SAME_LINE,
  WHILE_LOOP,
  MAP_STATEMENT,
  FILTER_STATEMENT,
  LIST_INITIALIZATION,
  LIST_APPEND,
  LIST_POP,
  LIST_COPY,
  FREE_TEXT,
  BREAK,
  CONTINUE,
  COMMENT,
  FOR_LOOP_ELEMENT,
  DICT_INIT,
  DICT_ADD,
  DICT_POP,
} from './block-types';

// utils
const parseBlock = ({ type, input1, input2, input3, input4, operator }) => {
  switch (type) {
    case INCREMENT:
      return [input1, '+=', input2].join(' ');
    case ASSIGNMENT:
      return [input1, '=', input2].join(' ');
    case FUNCTION_DECLARATION:
      return `def ${input1}(${input2}):`;
    case RETURN_STATEMENT:
      return `return ${input1}`;
    case FOR_LOOP:
      return `for ${input1} in range(${input2}):`;
    case FOR_LOOP_ELEMENT:
      return `for ${input1} in ${input2}:`;
    case IF_STATEMENT:
      return `if (${input1}):`;
    case ELIF_STATEMENT:
      return `elif (${input1}):`;
    case ELSE_STATEMENT:
      return `else:`;
    case PRINT_STATEMENT:
      return `print(${input1})`;
    case PRINT_SAME_LINE:
      return `print(${input1}, end = '${input2}')`;
    case WHILE_LOOP:
      return `while (${input1}):`;
    case MAP_STATEMENT:
      return `${input4} = list(map(lambda ${input1}: ${input2}, ${input3}))`;
    case FILTER_STATEMENT:
      return `${input4} = list(filter(lambda ${input1}: ${input2}, ${input3}))`;
    case LIST_INITIALIZATION:
      return `${input1} = [${input2}]`;
    case LIST_APPEND:
      return `${input1}.append(${input2})`;
    case LIST_POP:
      return `${input1} = ${input2}.pop()`;
    case LIST_COPY:
      return `${input1} = ${input2}.copy()`;
    case FREE_TEXT:
      return `${input1}`;
    case BREAK:
      return `break`;
    case CONTINUE:
      return `continue`;
    case COMMENT:
      return `# ${input1}`;
    case DICT_INIT:
      return `${input1} = {${input2}}`;
    case DICT_ADD:
      return `${input1}[${input2}] = ${input3}`;
    case DICT_POP:
      return `${input3} = ${input1}.pop(${input2})`;
    default:
      return '';
  }
};

const parseTree = ({ items, inputs }) => {
  if (items === [] || inputs === {}) {
    return [];
  }
  return parseTreeHelper({ items, inputs, indent: 0 });
};

const parseTreeHelper = ({ items, inputs, indent }) => {
  if (items === undefined) {
    return [];
  }

  let result = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    console.log(inputs[item['id']]);
    result.push({
      indent: indent,
      command: parseBlock(inputs[item['id']]),
      blockType: inputs[item['id'].toString()]['type'],
      blockId: item['id'],
    });

    let childBlocks = parseTreeHelper({
      items: items[i].children,
      inputs: inputs,
      indent: indent + 4,
    });

    result = result.concat(childBlocks);
  }

  return JSON.parse(JSON.stringify(result)); // deep copy
};

const getFileOutput = (parsedTree) => {
  var result = '';
  for (let i = 0; i < parsedTree.length; i++) {
    result += ' '.repeat(parsedTree[i].indent) + parsedTree[i].command;
    result += '\n';
  }
  return result;
};

const handlerStub = () => null;

const generateOptions = (operators, block_id) => {
  return operators.map((operator, index) => (
    <option
      type="view"
      id={operator + index + block_id}
      value={operator}
      style={{ fontFamily: 'monospace' }}
    >
      {operator}
    </option>
  ));
};

const removeSearch = (list, id) => {
  /* removes the element from list */
  return removeSearchHelper(list, null, id);
};

const removeSearchHelper = (list, parent, id) => {
  if (list === undefined) {
    return false;
  }

  for (let i = 0; i < list.length; i++) {
    if (list[i]['id'] === id) {
      // found
      let removed = list[i];
      list.splice(i, 1); // delete one element from index i

      if (!removed.hasOwnProperty('children')) {
        // no children to remove - covered
        // console.log('no children to remove');
        return true;
      }

      let childrenOfRemoved = removed.children;
      // console.log(`children of removed: ${JSON.stringify(childrenOfRemoved)}`);

      if (i > 0) {
        // attach to previous block - this is ambigiuous/by choice
        let previousBlock = list[i - 1];
        if (!previousBlock.hasOwnProperty('children')) {
          // covered
          previousBlock.children = [];
        }
        previousBlock.children =
          previousBlock.children.concat(childrenOfRemoved); // covered
      } else if (i === 0) {
        // attach to parent block at index 0 - covered
        if (parent === null) {
          // i.e. at the root - covered
          // console.log(`parent is null`);
          list.splice(0, 0, ...childrenOfRemoved);
        } else {
          // - covered
          parent.children.splice(0, 0, ...childrenOfRemoved); // insert at index 0
        }
      }
      return true;
      // outer one remove -> what is the behaviour of inner?
    }

    if (
      list[i].hasOwnProperty('children') &&
      removeSearchHelper(list[i]['children'], list[i], id)
    ) {
      return true;
    }
  }
  return false;
};

const copyJSON = (json) => {
  return JSON.parse(JSON.stringify(json));
};

const saveFile = ({ items, inputs, fileName }) => {
  // take in this.state
  console.log(parseTree({ items, inputs }));
  const outputString = getFileOutput(
    parseTree({
      items: items,
      inputs: inputs,
    }),
  );
  console.log(outputString);
  var blob = new Blob([outputString], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, `${fileName}.py`);
};

const findPath = (items, id) => {
  console.log(items, id);
  var path = [];

  for (let i = 0; i < items.length; i++) {
    if (items[i]['id'] === id) {
      path.push(i);
      return path;
    } else if (
      items[i].hasOwnProperty('children') &&
      items[i].children !== undefined &&
      items[i].children.length > 0
    ) {
      var result = findPath(items[i]['children'], id);
      if (result.length > 0) {
        path.push(i);
        path = path.concat(result);
        return path;
      }
    }
  }
  return path;
};

const getStatementNumber = (flatten, block_id) => {
  for (let i = 0; i < flatten.length; i++) {
    if (flatten[i].blockId === block_id) {
      return i;
    }
  }
  return -1;
};

const insertBlock = (items, path, block_id) => {
  const newItems = copyJSON(items);
  let curr = newItems;
  for (let i = 0; i < path.length - 1; i++) {
    curr = curr[path[i]]['children'];
  }

  curr.splice(path[path.length - 1], 0, { id: block_id }); // add block at the specified path

  return newItems;
};

const removeBlock = (items, inputs, item_id) => {
  const newItems = copyJSON(items);
  console.log(removeSearch(newItems, item_id) === true);
  const newInputs = copyJSON(inputs);
  delete newInputs[item_id];
  return { items: newItems, inputs: newInputs };
};

const getNextBlockId = (block_id, flatten) => {
  for (let i = 0; i < flatten.length - 1; i++) {
    // last block returns null
    if (flatten[i].blockId === block_id) {
      return flatten[i + 1].blockId;
    }
  }
  return null; // last block does not have next block
};

const getPrevBlockId = (block_id, flatten) => {
  if (flatten.length > 0 && flatten[0].blockId === block_id) {
    return null; // no previous block for first block
  }
  for (let i = 1; i < flatten.length; i++) {
    // last block returns null
    if (flatten[i].blockId === block_id) {
      return flatten[i - 1].blockId;
    }
  }
};

const isCodeValid = (tree) => {
  // returns an int, status code
  if (!isPrintStatementExists(tree)) {
    return 1;
  }
  return 0;
};

const isPrintStatementExists = (flatten) => {
  for (let i = 0; i < flatten.length; i++) {
    if (
      flatten[i].blockType === PRINT_STATEMENT ||
      flatten[i].blockType === PRINT_SAME_LINE
    ) {
      return true;
    }
  }
  return false;
};
const generateCode = (tree) => {
  return (
    <div>
      {tree.map(
        (
          row,
          index, // index is the line number
        ) => (
          // To-do: handle long statements
          // -2vh to 0vh
          // default: -1.5vh
          // To-do: can make this height flexible
          <div style={{ margin: '-0.5vh', fontSize: '2.5vh' }}>
            <div style={{ overflow: 'hidden' }}>
              <SyntaxHighlighter language="python">
                {String.fromCharCode(160).repeat(row.indent) + row.command}
              </SyntaxHighlighter>
            </div>
          </div>
        ),
      )}
    </div>
  );
};

const getClassName = (blockType) => {
  if (blockType === FOR_LOOP) {
    return 'for';
  }
  return 'view';
};

export {
  parseBlock,
  parseTree,
  getFileOutput,
  generateOptions,
  handlerStub,
  removeSearch,
  copyJSON,
  saveFile,
  findPath,
  getStatementNumber,
  getNextBlockId,
  getPrevBlockId,
  insertBlock,
  removeBlock,
  isCodeValid,
  generateCode,
  getClassName,
};
