import React from 'react';
import Button from '@material-ui/core/Button';

import DRAG_BUTTON from './images/drag-flick.png';
import DROP_BUTTON from './images/drop-here.png';
import Down from './images/angle-small-down-black.svg';
import Left from './images/angle-small-left-black.svg';

const height = '1.5vh';
const fontFamily = 'Courier New';
const fontSize = '1.5vh';
const THREE_VW_SIZE = (
  <input
    style={{
      width: '3vw',
      height: height,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

const ONE_VW_SIZE = (
  <input
    style={{
      width: '1vw',
      height: height,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

const HALF_VW_SIZE = (
  <input
    style={{
      width: '0.5vw',
      height: height,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

const TWO_VW_SIZE = (
  <input
    style={{
      width: '2vw',
      height: height,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

const getTextInBox = (text) => {
  let mapping = { '=': 'equal', '#': 'comment' };
  let inputClassName = text;
  if (mapping.hasOwnProperty(text)) {
    inputClassName = mapping[inputClassName];
  }
  return (
    <div style={{ display: 'inline-block', width: '1vw !important' }}>
      <input
        type="view"
        disabled
        className={inputClassName}
        size={text.length - 1}
        value={text}
        style={{
          fontFamily: 'monospace',
          fontSize: '14px',
          width: `${text.length}ch`,
        }}
      />
    </div>
  );
};

const getString = (text) => {
  let className = text;
  let mapping = {
    '=': 'equal',
    '+=': 'equal',
    '(': 'bracket',
    ')': 'bracket',
    '[': 'bracket',
    ']': 'bracket',
    ':': 'colon',
    ',': 'comma',
    '.': 'dot',
    '{': 'curly',
    '}': 'curly',
    "'": 'apostrophe',
  };

  if (mapping.hasOwnProperty(text)) {
    className = mapping[className];
  }
  return (
    <div
      style={{ display: 'inline-block', width: '1vw !important' }}
      className={className}
    >
      {text}
    </div>
  );
};

const INCREMENT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {THREE_VW_SIZE} += {THREE_VW_SIZE}
    </div>
  </Button>
);

const ASSIGNMENT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {THREE_VW_SIZE}
      &nbsp;=&nbsp;
      {THREE_VW_SIZE}
    </div>
  </Button>
);

const FUNCTION_DECLARATION_BLOCK = (
  <div>
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <div>
        def&nbsp;
        {THREE_VW_SIZE}({THREE_VW_SIZE}
        ):
      </div>
    </Button>
  </div>
);

const RETURN_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      return&nbsp;
      {THREE_VW_SIZE}
    </div>
  </Button>
);

const FOR_LOOP_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      for {ONE_VW_SIZE} in range({TWO_VW_SIZE}):
    </div>
  </Button>
);

const FOR_LOOP_ELEMENT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      for {ONE_VW_SIZE} in {TWO_VW_SIZE}:
    </div>
  </Button>
);

const FOUR_SPACES = String.fromCharCode(160).repeat(4);

const IF_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div style={{ textAlign: 'left' }}>
      if&nbsp;({THREE_VW_SIZE}):<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
      elif&nbsp;({THREE_VW_SIZE}):<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
      else:<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
    </div>
  </Button>
);

const WHILE_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>while ({THREE_VW_SIZE}):</div>
  </Button>
);

const BLANK_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>Blank</div>
  </Button>
);

const MAP_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {HALF_VW_SIZE} = list(map {HALF_VW_SIZE}: {HALF_VW_SIZE},{HALF_VW_SIZE})
    </div>
  </Button>
);

const FILTER_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {HALF_VW_SIZE} = list(filter{HALF_VW_SIZE}:{HALF_VW_SIZE},{HALF_VW_SIZE})
    </div>
  </Button>
);

const PRINT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>print({TWO_VW_SIZE})</div>
  </Button>
);

const PRINT_SAME_LINE_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      print({TWO_VW_SIZE}, end='{ONE_VW_SIZE}')
    </div>
  </Button>
);

const LIST_INITIALIZATION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {TWO_VW_SIZE} = [{HALF_VW_SIZE}]
    </div>
  </Button>
);

const LIST_APPEND_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {TWO_VW_SIZE}.append({ONE_VW_SIZE})
    </div>
  </Button>
);

const LIST_POP_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {TWO_VW_SIZE} = {TWO_VW_SIZE}.pop()
    </div>
  </Button>
);

const LIST_COPY_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {TWO_VW_SIZE} = {TWO_VW_SIZE}.copy()
    </div>
  </Button>
);

const LIST_LENGTH_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <div>
      {TWO_VW_SIZE} = len({TWO_VW_SIZE})
    </div>
  </Button>
);

const FREE_TEXT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <div style={{ textTransform: 'capitalize', display: 'inline-block' }}>
      T
    </div>
    <div style={{ display: 'inline-block' }}>ype your own code </div>
  </Button>
);

const BREAK_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <div style={{ display: 'inline-block' }}>break</div>
  </Button>
);

const CONTINUE_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <div style={{ display: 'inline-block' }}>continue</div>
  </Button>
);

const COMMENT_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <div style={{ display: 'inline-block', fontFamily: 'monospace' }}>
      #&nbsp;
    </div>
    <div style={{ display: 'inline-block' }}>{TWO_VW_SIZE}</div>
  </Button>
);

const OPEN_CURLY = '{';
const CLOSE_CURLY = '}';

const DICT_INIT_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <div>
        {TWO_VW_SIZE} = {OPEN_CURLY}
        {THREE_VW_SIZE}
        {CLOSE_CURLY}
      </div>
    </Button>
  );

const DICT_ADD_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <div>
        {TWO_VW_SIZE}[{ONE_VW_SIZE}] = {TWO_VW_SIZE}
      </div>
    </Button>
  );

const DICT_POP_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <div>
        {ONE_VW_SIZE} = {TWO_VW_SIZE}.pop({ONE_VW_SIZE})
      </div>
    </Button>
  );

const renderCollapseIcon = (isCollapsedObject) => {
  if (isCollapsedObject.isCollapsed) {
    return <img src={Left} width={20} height={20} alt="Left brace"></img>;
  }
  return <img src={Down} width={20} height={20} alt="Left brace"></img>;
};

export {
  THREE_VW_SIZE,
  DRAG_BUTTON,
  DROP_BUTTON,
  INCREMENT_BLOCK,
  ASSIGNMENT_BLOCK,
  FUNCTION_DECLARATION_BLOCK,
  RETURN_BLOCK,
  FOR_LOOP_BLOCK,
  FOR_LOOP_ELEMENT_BLOCK,
  IF_BLOCK,
  WHILE_BLOCK,
  BLANK_BLOCK,
  MAP_BLOCK,
  FILTER_BLOCK,
  PRINT_BLOCK,
  PRINT_SAME_LINE_BLOCK,
  LIST_INITIALIZATION_BLOCK,
  LIST_APPEND_BLOCK,
  LIST_POP_BLOCK,
  LIST_LENGTH_BLOCK,
  LIST_COPY_BLOCK,
  FREE_TEXT_BLOCK,
  BREAK_BLOCK,
  CONTINUE_BLOCK,
  COMMENT_BLOCK,
  DICT_INIT_BLOCK,
  DICT_ADD_BLOCK,
  DICT_POP_BLOCK,
  getTextInBox,
  getString,
  renderCollapseIcon,
}; // exporting constants
